import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

const News = () => {
  return (
    <Layout>
      <SEO title="News" />
      <iframe
        id="if1"
        title="news blog"
        width="100%"
        style={{ height: '100vh', border: 'none' }}
        src="https://blog.edgelogistics.com/"
      ></iframe>
    </Layout>
  )
}

export default News
